<template>
  <div class="modal-container">
    <transition name="fade" mode="out-in">
      <div class="modal-movie" v-if="isOpen">
        <div class="modal__overlay" v-on:click="onClose">
          <div class="close-button" v-on:click="onClose"><i class="fas fa-times-circle"></i></div>
        </div>
        <div class="modal__body" v-on:click="onClose">
          <div class="youtube__wrapper">
            <youtube
                :video-id="movieId"
                ref="youtube"
                :width="movieWidth()"
                :height="movieHeight()"
                :player-vars="playerVars"
            ></youtube>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["movie", "isOpen"],
  data: () => {
    return {
      playerVars: {
        autoplay: 1
      }
    }
  },
  computed: {
    movieId() {
      if ( this.movie ) {
        return this.movie.movie_id || this.movie.movieId
      }
      else {
        return ''
      }
    }
  },

  methods: {
    onClose() {
      this.$emit("onClose");
    },
    movieWidth() {
      if (window.innerWidth >= 640) {
        return 640
      }
      else {
        return (window.innerWidth - 30)
      }
    },
    movieHeight() {
      return this.movieWidth() * 0.75
    }
  }
};
</script>