import axios from 'axios'
import StringCaseUtil from "./string-case-util";

const token = document.querySelector('[name="csrf-token"]') || {content: 'no-csrf-token'}
// axios.defaults.xsrfCookieName = 'CSRF-TOKEN'
// axios.defaults.xsrfHeaderName = 'X-CSRF-Token'
export default axios.create({
	headers: {
		'X-Requested-With': 'XMLHttpRequest',
		common: {
			'X-CSRF-Token': token.content
		}
	},
	transformResponse: [
		...axios.defaults.transformResponse,
		function(data, headers) {
			if (!headers['content-type'] || headers['content-type'].indexOf('application/json') === -1) {
				return data
			}
			else {
				return StringCaseUtil.toCamel(data)
			}
		}
	],
	transformRequest: [
		function(data, headers) {
			if (data instanceof FormData) {
				return data
			}
			return StringCaseUtil.toSnake(data)
		},
		...axios.defaults.transformRequest
	],

	// GETリクエストのパラメーターをsnake_caseに変換。
	paramsSerializer(params) {
		const obj = StringCaseUtil.toSnake(params)
		return Object.keys(obj).map((key) => {
			if(obj[key]) {
				return `${key}=${obj[key]}`
			}
			else {
				return ''
			}
		}).join('&')
	},


})

