const movies = [
    {
        id: 21,
        title: '【御来光】富士山＆南アルプスから昇る朝日！南アルプスを守るお話＿お家で御来光をみよう編',
        thumbnailUrl: 'http://localhost:3000/active_storage_images/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBMUT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--5bfad9e93b1e05598de29ee5ced133e45ebe4fb9/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lJYW5CbkJqb0dSVlE2QzNKbGMybDZaVWtpRERnd01IZzRNREFHT3daVSIsImV4cCI6bnVsbCwicHVyIjoidmFyaWF0aW9uIn19--9e0b34e9fa61abe203ca012f3d5d5dfea05e98c7/thumbnail-6r4acatyRuY.jpg',
        caption: 'キャプションが入ります。キャプションが入ります。キャプションが入ります。キャプションが入ります。キャプションが入ります。',
        updatedAt: '2022-01-11T08:52:29.596+09:00',
        movieId: '6r4acatyRuY',
        tags: [
            '南アルプスを守る話'
        ]
    },
    {
        id: 22,
        title: '南アルプスを守るお話',
        thumbnailUrl: 'http://localhost:3000/active_storage_images/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBMQT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--f6119b3f9ba480cf18626143a1557629f81111cc/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lJYW5CbkJqb0dSVlE2QzNKbGMybDZaVWtpRERnd01IZzRNREFHT3daVSIsImV4cCI6bnVsbCwicHVyIjoidmFyaWF0aW9uIn19--9e0b34e9fa61abe203ca012f3d5d5dfea05e98c7/thumbnail-rC1iZ6aBcl4.jpg',
        caption: 'キャプションが入ります。キャプションが入ります。キャプションが入ります。キャプションが入ります。キャプションが入ります。',
        updatedAt: '2022-01-22T08:52:29.596+09:00',
        movieId: 'rC1iZ6aBcl4',
        tags: [
            '南アルプスを守る話'
        ]
    },
    {
        id: 23,
        title: '南アルプスを守るお話',
        thumbnailUrl: 'http://localhost:3000/active_storage_images/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBMQT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--f6119b3f9ba480cf18626143a1557629f81111cc/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lJYW5CbkJqb0dSVlE2QzNKbGMybDZaVWtpRERnd01IZzRNREFHT3daVSIsImV4cCI6bnVsbCwicHVyIjoidmFyaWF0aW9uIn19--9e0b34e9fa61abe203ca012f3d5d5dfea05e98c7/thumbnail-rC1iZ6aBcl4.jpg',
        caption: 'キャプションが入ります。キャプションが入ります。キャプションが入ります。キャプションが入ります。キャプションが入ります。',
        updatedAt: '2022-01-22T08:52:29.596+09:00',
        movieId: 'rC1iZ6aBcl4',
        tags: [
            '南アルプスを守る話'
        ]
    },
    {
        id: 24,
        title: '【御来光】富士山＆南アルプスから昇る朝日！南アルプスを守るお話＿お家で御来光をみよう編',
        thumbnailUrl: 'http://localhost:3000/active_storage_images/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBMUT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--5bfad9e93b1e05598de29ee5ced133e45ebe4fb9/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lJYW5CbkJqb0dSVlE2QzNKbGMybDZaVWtpRERnd01IZzRNREFHT3daVSIsImV4cCI6bnVsbCwicHVyIjoidmFyaWF0aW9uIn19--9e0b34e9fa61abe203ca012f3d5d5dfea05e98c7/thumbnail-6r4acatyRuY.jpg',
        caption: 'キャプションが入ります。キャプションが入ります。キャプションが入ります。キャプションが入ります。キャプションが入ります。',
        updatedAt: '2022-01-11T08:52:29.596+09:00',
        movieId: '6r4acatyRuY',
        tags: [
            '南アルプスを守る話'
        ]
    },

]
export default movies
