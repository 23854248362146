<template>
  <div>
    <div class="movie-container" v-on:scroll="scrollContainer" ref="container">
      <movie-element v-for="movie in movies" :movie="movie" :key="movie.id" v-on:showMovie="showMovie"></movie-element>
    </div>
    <div class="dot-container">
      <span :class="'dot ' + (index === currentIndex ? 'active' : '') " v-for="(movie,index) in movies" v-on:click="showElement(index)"></span>
    </div>
    <movie-modal v-if="isShowMovie"
                 :movie="currentMovie"
                 :is-open="isShowMovie"
                 v-on:onClose="modalClose()"
    />
  </div>
</template>

<script>
import movieElement from "./movie-element"
import movieModal from "../../movie-modal"
import Vue from 'vue'

import VueYoutube from 'vue-youtube'
//dummy
import movies from "../../../js/public/data/movies"
import axios from "../../../js/axios-extend";

Vue.use(VueYoutube)

export default {
  name: "movie-list",
  components: { movieElement, movieModal },
  props: [ 'searchParams', 'page', 'limit'],
  mounted() {
    if (this.searchParams) {
      this.getData();
    }
    else {
      this.movies = movies
    }
    this.currentPage = this.page
  },
  data: () => {
    return {
      movies: [],
      currentIndex: 0,
      currentPage: 1,
      isShowMovie: false,
      currentMovie: null,
    }
  },
  methods: {
    showMovie(movie) {
      this.currentMovie = movie
      this.isShowMovie = true
    },
    modalClose() {
      this.currentMovie = null
      this.isShowMovie = false
    },
    getData(optionsParams = {}) {
      const params = this.searchParams
      if (this.limit > 0) {
        params.limit = this.limit;
      }
      params.page = optionsParams.page || this.currentPage

      axios.get('/movies.json', { params: params } ).then(response => {
        if( response.data.status === 200 ) {
          this.movies = response.data.movies
          this.$emit('pageChange', response.data.page, response.data.pageCount)
        }
      });
    },
    showElement(index) {
      this.currentIndex = index
      let x = 0;
      if(index === this.movies.length - 1) {
        x = this.$refs.container.scrollWidth - this.$refs.container.offsetWidth
      }
      else {
        x = this.scrollXList()[index]
      }
      this.$refs.container.scrollTo({
        top: 0,
        left: x,
        behavior: 'smooth'
      });
    },
    elementWidth() {
      const child  = this.$refs.container.children[0]
      return child.offsetWidth
    },

    scrollXList() {
      const list = []
      for (let i = 0 ; i < this.movies.length; i ++ ) {
        list.push( ((this.elementWidth() + 30 )  * i ) - (this.$refs.container.offsetWidth / this.movies.length ) * i)
      }
      return list
    },

    scrollContainer(event) {
      const position = event.currentTarget.scrollLeft
      const list = this.scrollXList()
      let index = 0
      for( ; index < list.length; index ++ ) {
        if (position < list[index]) {
          break
        }
      }

      if(index === 0) {
        this.currentIndex = 0
      }
      else {
        this.currentIndex = index - 1
      }
    }

  }
}
</script>
