const StringCaseUtil = {

	isArray(a) {
		return Array.isArray(a)
	},

	isObject(o) {
		return o === Object(o) && !this.isArray(o) && typeof o !== 'function'
	},

	snakeToCamel(p){
		//_+小文字を大文字にする(例:_a を A)
		return p.replace(/_./g,
			function(s) {
				return s.charAt(1).toUpperCase()
			}
		);
	},

	camelToSnake (p){
		//大文字を_+小文字にする(例:A を _a)
		return p.replace(/([A-Z])/g,
			function(s) {
				return '_' + s.charAt(0).toLowerCase()
			}
		);
	},

	toCamel(o) {
		if (this.isObject(o)) {
			const n = {}
			Object.keys(o).forEach((k) => {
				n[this.snakeToCamel(k)] = this.toCamel(o[k])
			})
			return n
		}
		else if (this.isArray(o)) {
			return o.map((i) => {
				return this.toCamel(i)
			})
		}
		return o
	},

	toSnake(o) {
		if (this.isObject(o)) {
			const n = {}
			Object.keys(o).forEach((k) => {
				n[this.camelToSnake(k)] = this.toSnake(o[k])
			})
			return n
		}
		else if (this.isArray(o)) {
			return o.map((i) => {
				return this.toSnake(i)
			})
		}
		return o
	}
}
export default StringCaseUtil
