<template>
  <div class="movie-element">
    <img :src="bgMovie" alt="bgMovie" class="bg-image"/>
    <div class="photo-wrap">
      <div class="photo">
        <img :src="movie.thumbnailUrl" :alt="movie.title" v-on:click="showMovie"/>
      </div>
    </div>
    <div class="body">
      <div class="title">{{ movie.title }}</div>
      <div class="content">
        <div class="date"><i class="far fa-calendar-alt"></i> {{date}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import tag from '../common/tag'
import bgMovie from '../../../img/bg-movie.png'
import dateUtil from "../../../js/date-util";

export default {
  name: "movie-element",
  props: ['movie'],
  components: { tag },
  data: ()=> {
    return {
      bgMovie: bgMovie
    }
  },
  computed: {
    date() {
      return dateUtil.formatDate(new Date(this.movie.updatedAt),'YYYY.MM.DD')
    }
  },
  methods: {
    showMovie() {
      this.$emit('showMovie', this.movie )
    }
  }
}
</script>

<style scoped>

</style>