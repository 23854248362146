<template>
  <a :href="href || '#'">
    <span class="tag">{{name}}</span>
  </a>
</template>

<script>
export default {
  name: "tag",
  props: ['name', 'href']

}
</script>
