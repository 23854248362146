import Vue from 'vue'
import movieList from "../../components/public/movie/movie-list"
import "scroll-behavior-polyfill"

document.addEventListener('DOMContentLoaded', () => {
    const el = document.querySelector("#movie-list")
    if (!el) return
    const params = JSON.parse(el.dataset.params || 'null')
    const limit = parseInt(el.dataset.limit ) || 0
    let page = parseInt(el.dataset.page) || 1
    const app = new Vue({
        render: h => h(movieList, {
            props: {
                searchParams: params,
                limit: limit,
                page: page
            }}),
    }).$mount();
    el.appendChild(app.$el);
});
